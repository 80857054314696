















































import Component from "vue-class-component";
import Vue from "vue";
import {PasswordResetPayload} from "@/store/modules/strapi/types";
import {MetaInfo} from "vue-meta";
import { Strapi } from '@/store/modules/strapi/service';
import {TranslateResult} from "vue-i18n";

@Component({
  metaInfo(): MetaInfo {
    return (this as ResetPassword).metaTags;
  }
})
export default class ResetPassword extends Vue {
  resetForm: PasswordResetPayload = {
    password: '',
    confirmPassword: '',
    code: ''
  };
  submitted = false;
  loading = false;
  resetOk = false;
  resetFailed = false;
  resetErrorMessage: TranslateResult = this.$t('errorOccurredContact');

  mounted() {
    if(!this.$route.query.code){
      this.$router.replace('/');
    } else {
      this.resetForm.code = this.$route.query.code.toString();
    }
  }

  get metaTags(): MetaInfo {
    return {
      title: this.$t('resetYourPassword').toString()
    };
  }

  abortReset() {
    this.$router.push('/');
  }

  async resetPassword() {
    this.submitted = true;
    if (this.anyValidationErrors) {
      return;
    }

    this.loading = true;
    const response = await Strapi.instance().resetPassword(this.resetForm)
        .catch(error => error.response);
    this.loading = false;

    switch (response.status) {
      case 200:
        this.resetOk = true
        return;
      default:
        this.resetFailed = true;
    }
  }

  get passwordError() {
    const regx = /^[\s\S]{8,32}$/i;
    if (this.submitted) {
      if (!this.resetForm.password) {
        return ` - ${this.$t('requiredField')}`;
      }
      if (!regx.test(this.resetForm.password)) {
        return ` - ${this.$t('passwordComplexity')}`;
      }
    }
    return null;
  }

  get confirmPasswordError() {
      if (this.resetForm.password.length > 0) {
        if (this.resetForm.confirmPassword.length < 1 && this.submitted) {
          return ` - ${this.$t('requiredField')}`;
        } else if (this.resetForm.confirmPassword.length > 0) {
          return this.resetForm.password.localeCompare(this.resetForm.confirmPassword) !== 0 ?
              ` - ${this.$t('differentPasswords')}` : null;
        }
      }
    return null;
  }

  get anyValidationErrors() {
    return this.passwordError != null || this.confirmPasswordError != null;
  }

}
