































import Component from "vue-class-component";
import Vue from "vue";
import {PasswordResetRequestPayload} from "@/store/modules/strapi/types";
import {MetaInfo} from "vue-meta";
import { Strapi } from '@/store/modules/strapi/service';
import {TranslateResult} from "vue-i18n";

/* eslint @typescript-eslint/no-var-requires: "off" */
const {EventBus} = require("@/event-bus");

@Component({
  metaInfo(): MetaInfo {
    return (this as ForgotPassword).metaTags;
  }
})
export default class ForgotPassword extends Vue {
  forgotForm: PasswordResetRequestPayload = {
    email: ''
  };
  submitted = false;
  loading = false;
  forgotFailed = false;
  forgotOk = false;
  forgotErrorMessage: TranslateResult = this.$t('errorOccurredContact');

  mounted() {
    EventBus.$emit('login-modal-close');
  }

  get metaTags(): MetaInfo {
    return {
      title: this.$t('forgotYourPassword').toString()
    };
  }

  abortReset() {
    this.$router.push('/');
  }

  async resetPassword() {
    this.submitted = true;
    this.submitted = true;
    if (this.anyValidationErrors) {
      return;
    }

    this.loading = true;
    const response = await Strapi.instance().resetPasswordRequest(this.forgotForm)
        .catch(error => error.response);
    this.loading = false;

    switch (response.status) {
      case 400:
      case 200:
        this.forgotOk = true;
        return;
      default:
        this.forgotFailed = true;
    }
  }

  get usernameError() {
    const regx = /^\S+@\S+\.\S+$/;
    return (!this.forgotForm.email && !this.submitted) || regx.test(this.forgotForm.email)
        ? null : ` - ${this.$t('inputValidEmail')}`;
  }

  get anyValidationErrors() {
    return this.usernameError != null;
  }

}
